import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

import {
  getCookie,
  setCookie,
  getUrlParam
} from "@/plugins/utils";
import {
  loginApi
} from "../api/login";
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter);



const routes = [{
  path: "/search/index",
  component: () =>
    flag ? import( /* webpackChunkName: "index" */ "@/views/search/index") : import( /* webpackChunkName: "index" */ "@/views/search/pcIndex"),
  meta: {
    title: "搜索"
  },
},
{
  path: "/filter/index",
  component: () =>
    import( /* webpackChunkName: "index" */ "@/views/filter/index"),
  meta: {
    title: "筛选"
  },
},
{
  path: "/login",
  component: () =>
    import( /* webpackChunkName: "index" */ "@/views/login/index"),
  meta: {
    title: "登录"
  },
},
{
  path: "/selectMember/index",
  component: () =>
    import( /* webpackChunkName: "index" */ "@/views/selectMember/index"),
  meta: {
    title: "选择成员"
  },
},
{
  path: "/",
  component: () =>
    flag ? import( /* webpackChunkName: "index" */ "@/views/index/index") : import( /* webpackChunkName: "index" */ "@/views/index/pcIndex"),
  meta: {
    title: "首页"
  },
},
{
  path: "/market/index",
  component: () =>
    flag ? import( /* webpackChunkName: "index" */ "@/views/market/index") : import( /* webpackChunkName: "index" */ "@/views/market/pcIndex"),
  meta: {
    title: "市场"
  },
},
{
  path: "/consult/index",
  component: () =>
    flag ? import( /* webpackChunkName: "index" */ "@/views/consult/index") : import( /* webpackChunkName: "index" */ "@/views/consult/pcIndex"),
  meta: {
    title: "咨询"
  },
},
{
  path: "/myConsult/index",
  component: () =>
    import( /* webpackChunkName: "index" */ "@/views/myConsult/index"),
  meta: {
    title: "我的咨询"
  },
},
{
  path: "/consult/selectConsult",
  component: () =>
    import( /* webpackChunkName: "index" */ "@/views/consult/selectConsult"),
  meta: {
    title: "选择咨询"
  },
},
{
  path: "/consultMemberDetail/index",
  component: () =>
    import( /* webpackChunkName: "index" */ "@/views/consultMemberDetail/index"),
  meta: {
    title: "咨询人员详情"
  },
},
{
  path: "/my/index",
  component: () =>
    flag ? import( /* webpackChunkName: "index" */ "@/views/my/index") : import( /* webpackChunkName: "index" */ "@/views/my/pcIndex"),
  meta: {
    title: "我的"
  },
},
{
  path: "/saoyisao/detail",
  component: () =>
    import( /* webpackChunkName: "index" */ "@/views/saoyisao/detail"),
  meta: {
    title: "扫一扫"
  },
},

{
  path: "/companyDetail/index",
  component: () =>
    flag ? import( /* webpackChunkName: "index" */ "@/views/companyDetail/index") : import( /* webpackChunkName: "index" */ "@/views/companyDetail/pcIndex"),
  meta: {
    title: "企业详情"
  },
},
{
  path: "/pingjiaDetail/index",
  component: () =>
    import( /* webpackChunkName: "index" */ "@/views/pingjiaDetail"),
  meta: {
    title: "企业评价详情"
  },
},
{
  path: "/brandInfo/index",
  component: () =>
    flag ? import( /* webpackChunkName: "index" */ "@/views/brandInfo/index") : import( /* webpackChunkName: "index" */ "@/views/brandInfo/pcIndex"),
  meta: {
    title: "品牌详情"
  },
},
{
  path: "/findChance/index",
  component: () =>
    import( /* webpackChunkName: "index" */ "@/views/findChance"),
  meta: {
    title: "发现商机"
  },
},
{
  path: "/helpCenter/index",
  component: () =>
    import( /* webpackChunkName: "index" */ "@/views/helpCenter"),
  meta: {
    title: "帮助中心"
  },
},
{
  path: "/feedBack/index",
  component: () =>
    import( /* webpackChunkName: "index" */ "@/views/feedBack"),
  meta: {
    title: "意见反馈"
  },
},
{
  path: "/myChanceDetail/index",
  component: () =>
    import( /* webpackChunkName: "index" */ "@/views/myChanceDetail"),
  meta: {
    title: "我的商机"
  },
},
{
  path: "/myMarket/index",
  component: () =>
    import( /* webpackChunkName: "index" */ "@/views/myMarket"),
  meta: {
    title: "我的市场"
  },
},
{
  path: "/myRight/index",
  component: () =>
    import( /* webpackChunkName: "index" */ "@/views/myRight"),
  meta: {
    title: "我的权益"
  },
},
{
  path: "/myRightDetail/index",
  component: () =>
    import( /* webpackChunkName: "index" */ "@/views/myRight/detail"),
  meta: {
    title: "我的权益详情"
  },
},
{
  path: "/applyUse/index",
  component: () =>
    import( /* webpackChunkName: "index" */ "@/views/myRight/applyUse"),
  meta: {
    title: "申请使用"
  },
},

{
  path: "/chanceDetail/index",
  component: () =>
    import( /* webpackChunkName: "index" */ "@/views/chanceDetail"),
  meta: {
    title: "商机详情"
  },
},
{
  path: "/failedChanceDetail/index",
  component: () =>
    import( /* webpackChunkName: "index" */ "@/views/myMarket/editChance"),
  meta: {
    title: "商机详情"
  },
},
{
  path: "/myGrapChanceDetail/index",
  component: () =>
    import( /* webpackChunkName: "index" */ "@/views/myMarket/myGrapChanceDetail"),
  meta: {
    title: "商机详情"
  },
},
{
  path: "/realTimeInfo/index",
  component: () =>
    import( /* webpackChunkName: "index" */ "@/views/realTimeInfo"),
  meta: {
    title: "行业资讯"
  },
},
{
  path: "/addChance/index",
  component: () =>
    import( /* webpackChunkName: "index" */ "@/views/addChance"),
  meta: {
    title: "发布商机"
  },
},
{
  path: "/applyAdd/index",
  component: () =>
    import( /* webpackChunkName: "index" */ "@/views/applyAdd"),
  meta: {
    title: "申请添加"
  },
},
{
  path: "/findCustom/index",
  component: () =>
    flag ? import( /* webpackChunkName: "index" */ "@/views/findCustom/index") : import( /* webpackChunkName: "index" */ "@/views/findCustom/pcIndex"),
  meta: {
    title: "发现客户"
  },
},
{
  path: "/findCustom/search",
  component: () =>
    import( /* webpackChunkName: "index" */ "@/views/findCustom/search"),
  meta: {
    title: "搜索客户"
  },
},
{
  path: "/classify/index",
  component: () =>
    import( /* webpackChunkName: "index" */ "@/views/classify"),
  meta: {
    title: "分类"
  },
},
{
  path: "/qyBrandManage/index",
  component: () =>
    import( /* webpackChunkName: "index" */ "@/views/qyBrandManage"),
  meta: {
    title: "企业品牌管理"
  },
},
{
  path: "/qyAddBrand/index",
  component: () =>
    import( /* webpackChunkName: "index" */ "@/views/qyAddBrand"),
  meta: {
    title: "企业品牌添加"
  },
},
{
  path: "/qyIndex/index",
  component: () =>
    import( /* webpackChunkName: "index" */ "@/views/qyIndex"),
  meta: {
    title: "管理首页"
  },
},
{
  path: "/grapMessage/index",
  component: () =>
    import( /* webpackChunkName: "index" */ "@/views/chanceDetail/grapMessage"),
  meta: {
    title: "抢订留言"
  },
},
{
  path: "/realTimeInfo/detail",
  component: () =>
    import( /* webpackChunkName: "index" */ "@/views/realTimeInfo/detail"),
  meta: {
    title: "资讯详情"
  },
},
{
  path: "/helpCenter/detail",
  component: () =>
    import( /* webpackChunkName: "index" */ "@/views/helpCenter/detail"),
  meta: {
    title: "帮助中心详情"
  },
},
{
  path: "/qyChance/index",
  component: () =>
    import( /* webpackChunkName: "index" */ "@/views/qyChance/index"),
  meta: {
    title: "企业商机列表"
  },
},
// 管理端修改企业商机
{
  path: "/qyChance/edit",
  component: () =>
    import( /* webpackChunkName: "index" */ "@/views/qyChance/editChance"),
  meta: {
    title: "修改企业商机"
  },
},
// 管理端企业商机详情
// {
//   path: "/qyChance/chanceDetail",
//   component: () =>
//     import(/* webpackChunkName: "index" */ "@/views/qyChance/GrapChanceDetail"),
//   meta: { title: "修改企业商机" },
// },
// 管理端商机详情
{
  path: "/manager/ChanceDetail",
  component: () =>
    import( /* webpackChunkName: "index" */ "@/views/qyChanceDetail"),
  meta: {
    title: "修改企业商机"
  },
},
{
  path: "/payPopu/index",
  component: () =>
    import( /* webpackChunkName: "index" */ "@/views/payPopu/index"),
  meta: {
    title: "支付跳转页面"
  },
},
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

let flag = navigator.userAgent.match(
  /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
);
let orPath = null

function checkLogin1(to, from, next) {

  let token = getCookie('service_token')
  console.log('进入checkLogin')
  if (!token) {
    return false
  }
  return true
}

router.beforeEach(async (to, from, next) => {
  try {
    // console.log(checkLogin1(to, from, next))
    if (to.path == '/login') {
      orPath = localStorage.getItem('topath')
      // localStorage.removeItem('topath')
      if (checkLogin1(to, from, next) == true) {
        next({ path: '/', })
        return
      }
      // console.log('登录前url', from.path)
      // console.log('进入/login')
      console.log('orPath', orPath);
      let code = getUrlParam('code')
      // console.log('code', code)
      if (code) {
        loginApi.login({
          code
        }).then(res => {
          // console.log('res2344', res)
          setCookie("service_token", res.data);
          store.dispatch('JudgeAdmin')
          store.commit('setFalg', flag);

          // console.log("设置成功cookie");
          // console.log('orPath', orPath)
          // next({
          //   path: "/",
          // })
          next({
            path: orPath ? orPath : '/',
          })

        })
      } else {
        next()
      }
    } else {
      orPath = to.path
      // console.log('当前url', to.path)
      console.log('from.path', from.path)
      if (checkLogin1(to, from, next) == false) {
        console.log('当前url', to.fullPath)
        localStorage.setItem('topath', to.fullPath)
        console.log('from.path', from.path)
        // console.log('进入登录之前')
        next({
          path: "/login",
        });
        // console.log('进入登录之后')
      } else {
        next()
      }
    }
  } catch (e) {
    next();
  }
});

// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location, onResolve, onReject) {
//   if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
//   return originalPush.call(this, location).catch(err => err)
// }

export default router;