<template>
  <div class="pcIndex">
    <a-layout id="components-layout-demo-custom-trigger">
      <a-layout-sider v-model="collapsed" :trigger="null" collapsible>
        <div class="logo" ><img :src="userInfo.avatar" alt=""></div>
        <a-menu theme="dark" mode="inline">
          <a-menu-item key="1" @click="changeMenu('/')">
            <a-icon type="home" />
            <span> &nbsp;首页</span>
          </a-menu-item>
          <a-menu-item key="2" @click="changeMenu('/market/index')">
            <a-icon type="user" />
            <span> &nbsp;市场</span>
          </a-menu-item>
          <a-menu-item key="3" @click="changeMenu('/consult/index')">
            <a-icon type="message" />
            <span> &nbsp;咨询</span>
          </a-menu-item>
          <a-menu-item key="4" @click="changeMenu('/my/index')">
            <a-icon type="user" />
            <span> &nbsp;我的</span>
          </a-menu-item>
          <a-menu-item key="5" v-show="$store.state.admin == 1" @click="changeMenu('/qyIndex/index')">
            <a-icon type="setting" />
            <span> &nbsp;管理</span>
          </a-menu-item>
        </a-menu>
      </a-layout-sider>
      <a-layout>
        <a-layout-header style="background: #fff; padding: 0">
          <a-icon
            class="trigger"
            :type="collapsed ? 'menu-unfold' : 'menu-fold'"
            @click="() => (collapsed = !collapsed)"
          />
        </a-layout-header>
        <a-layout-content
          :style="{
            margin: '24px 16px',
            padding: '24px',
            background: '#fff',
            minHeight: '280px',
          }"
        >
          <router-view />
        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>
</template>
<script>
import { myApi } from "../api/my";

export default {
  data() {
    return {
      userInfo:'',
      collapsed: false,
      active: Number(sessionStorage.getItem("active")) || 0,
      // admin:sessionStorage.getItem('isAdmin')==null?this.$store.state.admin:sessionStorage.getItem('isAdmin')
    };
  },
  created() {
    this.getUserInfo()
  },
  methods: {
    //路由内容切换
    changeMenu(route) {
      console.log("33434", route);
      this.$router.push(route);
    },
    getUserInfo() {
      myApi.myInfo().then((res) => {
        this.userInfo = res.data;
        console.log(res);
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .van-tabbar--fixed {
  left: unset !important;
}
/deep/ .ant-layout-sider {
  background-color: #3974c6;
}
/deep/ .ant-menu-dark {
  background-color: #3974c6;
}
/deep/ .ant-layout-header{
  height: 0;
}
#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}
#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 42px;
  // background: rgba(255, 255, 255, 0.2);
  margin: 20px;
}
img{
  margin-left: 14px;
  width: 50px;
  height: 50px;
}
</style>